<template>
  <div
    class="absolute w-full h-full text-white flex items-center justify-center z-0"
  >
    <div class="flex flex-col gap-2 items-center justify-center">
      <div>
        <img src="@/assets/icon.png" class="w-10 h-10" />
      </div>
      <div class="text-gray-500">Loading Game</div>
    </div>
  </div>
  <iframe class="absolute w-full h-full z-1" :src="url" />
</template>
<script setup>
import { useRoute } from "vue-router";

const route = useRoute();
const SESSION_ID = Date.now() + Math.ceil(Math.random() * 100);

let url = `https://gs7.qa.markortech.com/api/games/launch/DEMO-${SESSION_ID}?gameSkin=${route.params.gameId}&brand=TEST-BRAND&language=en`;

if (route.params.gameId === "budai-wilds") {
  url = `https://gs7-gl.uat.markortech.com/api/games/launch/DEMO-${SESSION_ID}?gameSkin=${route.params.gameId}&brand=TEST-BRAND&language=en`;
}
</script>
