<template>
  <footer>
    <div class="py-5 px-0 border-t border-gray-800">
      <div class="container text-center">
        <div class="grid md:grid-cols-2 items-center">
          <div class="md:text-left text-center">
            <p class="mb-0 text-gray-400">© {{ year }} PLAYNOVA</p>
          </div>
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </div>
  </footer>
</template>

<script setup>
const year = new Date().getFullYear();
</script>
