<template>
  <div class="text-base text-white bg-[#000000]">
    <app-header />
    <app-hero />
    <div class="mb-14"></div>
    <app-separator />
    <app-features />
    <app-separator />
    <app-games />
    <app-footer />
  </div>
</template>

<script setup>
import { onMounted, ref, provide } from "vue";
import feather from "feather-icons";

import AppHero from "../components/AppHero.vue";
import AppHeader from "../components/AppHeader.vue";
import AppFeatures from "../components/AppFeatures.vue";
import AppGames from "../components/AppGames.vue";
import AppSeparator from "../components/AppSeparator.vue";
import AppFooter from "../components/AppFooter.vue";

const activeGame = ref(null);

provide("activeGame", activeGame);

onMounted(() => {
  feather.replace();
});
</script>
