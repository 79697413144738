<template>
  <section class="relative table w-full overflow-hidden">
    <div class="pt-20 pb-5">
      <div class="container">
        <div class="flex flex-col md:flex-row gap-10 items-center">
          <div class="w-2/3 md:w-1/2 md:p-10">
            <img src="@/assets/hero.png" />
          </div>
          <div class="flex-1 text-center md:text-left">
            <h4
              class="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white"
            >
              IN<span class="text-[#a98445]">NOVA</span>TION IN SLOTS CREATION
            </h4>
            <p class="text-[#a98445] text-lg max-w-xl font-light">
              Innovative, diverse, certified, premium content with engagement
              tools that will entertain players responsibly
            </p>
          </div>
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </div>
    <div
      class="overflow-hidden after:content-[''] after:absolute after:h-56 after:w-56 after:bg-yellow-600/5 after:top-10 after:left-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"
    ></div>
    <div
      class="overflow-hidden after:content-[''] after:absolute after:h-[512px] after:w-[512px] after:bg-yellow-600/5 after:top-0 after:-right-5 after:-z-1 after:rounded-full"
    ></div>
  </section>
</template>

<script>
export default {
  name: "AppHero",
  props: {
    msg: String,
  },
};
</script>
