<template>
  <section class="md:py-24 py-16">
    <div class="container">
      <app-heading :title="title" titleCssClass="text-white" />

      <div
        class="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-8 gap-[20px] items-start"
      >
        <div class="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
          <div class="grid grid-cols-1 gap-[20px]">
            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out"
              >
                <i data-feather="globe" class="w-5 h-5"></i>
              </div>
              <div
                class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4"
              >
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Games certified<br />
                  in regulated markets
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  Delivering certified content for the UK, Gibraltar, Malta,
                  Spain, Portugal, Ontario and coming soon Sweden, Romania and
                  Denmark.
                </p>
              </div>
            </div>
            <!--end feature-->

            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out"
              >
                <i data-feather="archive" class="w-5 h-5"></i>
              </div>
              <div
                class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4"
              >
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Diverse game portfolio
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  Our games portfolio provides a wide range of content: slots,
                  table games, scratch cards, social games, video bingo, side
                  games.
                </p>
              </div>
            </div>
            <!--end feature-->

            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out"
              >
                <i data-feather="zap" class="w-5 h-5"></i>
              </div>
              <div
                class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4"
              >
                <h4 class="mb-0 font-medium text-[#a98445]">
                  High-quality<br />
                  and premium speed
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  We provide premium quality content in terms of design, math,
                  engine and very importantly, speed.
                </p>
              </div>
            </div>

            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex md:order-2 order-1 align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out"
              >
                <i data-feather="tool" class="w-5 h-5"></i>
              </div>
              <div
                class="flex-1 md:order-1 order-2 md:text-right md:mr-4 md:ml-0 ml-4"
              >
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Engagement tool<br />
                  and client portal
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  Discover Playnova Plus, our powerful suite of engagement
                  tools, and Playnova Portal, our proficient client hub that
                  delivers accurate roadmap overview and game insights.
                </p>
              </div>
            </div>
            <!--end feature-->
          </div>
        </div>

        <div
          class="lg:col-span-4 md:col-span-12 lg:mx-8 lg:order-2 order-1 self-center"
        >
          <img
            src="@/assets/images/feature.png"
            class="mx-auto md:max-w-[300px]"
            alt=""
          />
        </div>

        <div class="lg:col-span-4 md:col-span-6 order-3">
          <div class="grid grid-cols-1 gap-[30px]">
            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl transition-all duration-500 ease-in-out"
              >
                <i data-feather="calendar" class="w-5 h-5"></i>
              </div>
              <div class="flex-1 ml-4">
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Regular and reliable game release roadmap
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  We release a minimum of 2 games per month. Our games are
                  planned and developed well in advance to ensure we never miss
                  a release date.
                </p>
              </div>
            </div>
            <!--end feature-->

            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl transition-all duration-500 ease-in-out"
              >
                <i data-feather="heart" class="w-5 h-5"></i>
              </div>
              <div class="flex-1 ml-4">
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Commitment to responsible gambling (RG)
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  Our games are developed with RG best practices in mind. All
                  games are developed for an adult audience and we are
                  constantly working on delivering a safe user experience.
                </p>
              </div>
            </div>
            <!--end feature-->

            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl transition-all duration-500 ease-in-out"
              >
                <i data-feather="settings" class="w-5 h-5"></i>
              </div>
              <div class="flex-1 ml-4">
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Flexible configuration of games
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  Operators can customize limits, RTPs, responsible gaming
                  features and even branding. You can adapt the game for your
                  target market.
                </p>
              </div>
            </div>
            <!--end feature-->

            <div
              class="group flex transition-all duration-500 ease-in-out xl:p-3"
            >
              <div
                class="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-white/5 group-hover:bg-[#a98445] group-hover:text-white text-[#a98445] rounded-full text-2xl transition-all duration-500 ease-in-out"
              >
                <i data-feather="map" class="w-5 h-5"></i>
              </div>
              <div class="flex-1 ml-4">
                <h4 class="mb-0 font-medium text-[#a98445]">
                  Multiple languages and currencies
                </h4>
                <p class="text-gray-400 text-sm mt-3">
                  With a wide choice of languages and currencies available,
                  Playnova provides truly market-localized content for your
                  players.
                </p>
              </div>
            </div>
            <!--end feature-->
          </div>
        </div>
      </div>
      <!--end grid-->
    </div>
    <!--end container-->
  </section>
</template>

<script setup>
import AppHeading from "./AppHeading.vue";

const title = `<span class="text-[#a98445]">PLAY</span> THE <span class="text-[#a98445]">NOVA</span> WAY`;
</script>
