import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import AppHome from "./pages/AppHome.vue";
import AppGame from "./pages/AppGame.vue";
import App from "./App.vue";

import "./main.css";

const routes = [
  { path: "/", component: AppHome },
  { path: "/game/:gameId", component: AppGame },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
