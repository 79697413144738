<template>
  <nav class="topnav">
    <div class="container">
      <div class="flex justify-between items-center py-5">
        <a href="/">
          <img src="@/assets/logo.png" class="h-10 mx-auto md:m-0" />
        </a>
        <button
          v-if="activeGame"
          @click="closeGame"
          class="text-white flex gap-1 items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-square w-5 h-5"
            data-v-bb50a5e4=""
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="9" x2="15" y2="15"></line>
            <line x1="15" y1="9" x2="9" y2="15"></line>
          </svg>
          <span>Close game</span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { inject, onMounted } from "vue";
import feather from "feather-icons";

const activeGame = inject("activeGame");

const closeGame = () => {
  activeGame.value = null;
};

onMounted(() => {
  feather.replace();
});
</script>

<style lang="scss" scoped>
.topnav {
  @apply fixed right-0 left-0 top-0 z-999 bg-[#000000] border-0 transition-all duration-500 ease-in-out;

  .logo {
    @apply block float-left text-white h-[74px] w-[250px] #{!important};
    &:focus {
      @apply outline-none;
    }
    background-image: url("@/assets/logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 350px;
  }
  &.scroll {
    @apply bg-white dark:bg-slate-900 border-none shadow;
  }
  &.defaultscroll {
    &.dark-menubar {
      .logo {
        @apply leading-[70px];
      }
    }
    &.scroll {
      .logo {
        @apply leading-[62px];
      }
    }
  }
  &.nav-sticky {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-800;

    .logo {
      .l-dark {
        @apply inline-block;
      }
      .l-light {
        @apply hidden;
      }
    }
  }
}

.logo {
  @apply font-bold text-[24px] mr-[15px] pt-0 px-[6px] pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 992px) {
  #topnav {
    &.scroll {
      @apply top-0;
      .navigation-menu {
        > li {
          > a {
            @apply py-5;
          }
        }
      }
    }
    &.scroll-active {
      .navigation-menu {
        > li {
          > a {
            @apply py-[25px];
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 min-h-[74px];
    .logo {
      .l-dark {
        @apply inline-block #{!important};
      }
      .l-light {
        @apply hidden #{!important};
      }
    }
    .container {
      @apply w-auto;
    }
  }

  #navigation {
    @apply absolute top-[74px] left-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
    &.open {
      @apply block overflow-y-auto;
    }
  }
}
</style>
